(function() {
    "use strict";

    angular.module("adminApp")
        .controller("SoftwareUpdateController", SoftwareUpdateController);

    SoftwareUpdateController.$inject = [ "$scope", "aerosAdminApi", "$uibModal", "Notification", "$sce" ];

    function SoftwareUpdateController($scope, aerosAdminApi, $uibModal, Notification, $sce) {
        $scope.$watch("commonReady", function(newVal) {
            if (!newVal) return;
            if (!$scope.allow["viewRogueSoftware"]) return $scope.reject();
            $scope.models = [ "GeminiCarrier", "GeminiOLTSModule" ];
            $scope.model = $scope.models[0];
            $scope.software = [];
            $scope.uploadUrl = "";
            $scope.pkg = "";

            $scope.deleteSoftware = deleteSoftware;
            $scope.addSoftware = addSoftware;
            $scope.editSoftware = editSoftware;
            $scope.uploadSoftwareVersion =uploadSoftwareVersion;
            $scope.showSoftwareUpdateInfo = showSoftwareUpdateInfo;
            reload();
            getUploadUrl();
        });
        function deleteSoftware(id) {

            $uibModal.open({
                size: "md",
                templateUrl: "confirmDeleteModal.html",
                controller: [ "$scope", function($scope) {
                    $scope.packageName = id;
                } ]
            }).result.then(function() {
                return aerosAdminApi.deleteRogueSoftware(id).then(reload)
                    .then(Notification.success.bind(Notification, "Package '" + id + "' deleted."));
            });
        }
        function addSoftware() {
        	$uibModal.open({
                size: "md",
                templateUrl: "addSoftware.html",
                controller: AddSoftwareModalController,
                resolve: {
                    reload: function() {
                        return reload;
                    },
                    uploadUrl: function() {
                        return aerosAdminApi.getUploadUrl().then(function(resp) {
                        	console.log("Hello from uploadUrl");
                            return $sce.trustAsResourceUrl(resp.data);
                        });
                    },
                    currentUser: function(){
                        return aerosAdminApi.getAdminUserProfile().success(function (data) {
                               return data.user.username;
                            })
                    }
                }
            });
        }

        function editSoftware(s) {
            $uibModal.open({
                size: "md",
                templateUrl: "editSoftware.html",
                controller: EditSoftwareModalController,
                resolve: {
                	software: function(){
                		return s;
                	},
                    reload: function() {
                        return reload;
                    }
                }
            });
        }

        function uploadSoftwareVersion(s) {
            $uibModal.open({
                size: "md",
                templateUrl: "editSoftwareVersion.html",
                controller: EditSoftwareVersionModalController,
                resolve: {
                    uploadUrl: function() {
                        return aerosAdminApi.getUploadUrl().then(function(resp) {
                            console.log("Hello from update uploadUrl");
                            return $sce.trustAsResourceUrl(resp.data);
                        });
                    },
                    software: function(){
                        return s;
                    },
                    reload: function() {
                        return reload;
                    },
                    currentUser: function(){
                        return aerosAdminApi.getAdminUserProfile().success(function (data) {
                            return data.user.username;
                        })
                    }
                }
            });
        }

        function showSoftwareUpdateInfo(s) {
            console.log("inside method");
            $uibModal.open({
                size: "md",
                templateUrl: "showSoftwareUpdateInfo.html",
                controller: showSoftwareUpdateInfoController,
                resolve: {
                    software: function(){
                        return s;
                    },
                    reload: function() {
                        return reload;
                    }
                }
            });
        }

        function reload() {
            aerosAdminApi.listRogueSoftware().
                then(function(resp) {return resp.data.software || [];}).
                then(function(packages) {
                    return packages.
                        filter(function(p) {return _.isObject(p)});
                }).
                then(function(packages) { $scope.software = packages; });
        }
        function getUploadUrl() {
            aerosAdminApi.getUploadUrl().then(function(resp) {
            	console.log("Hello from getUploadUrl");
                $scope.uploadUrl = $sce.trustAsResourceUrl(resp.data);
            });
        }
    }
    AddSoftwareModalController.$inject = [ "$scope", "aerosAdminApi", "reload", "uploadUrl","currentUser", "$uibModalInstance", "Notification" ];
    function AddSoftwareModalController($scope, aerosAdminApi, reload, uploadUrl,currentUser, $uibModalInstance, Notification) {
        $scope.uploadUrl = uploadUrl;
        $scope.uploadedFile = undefined;
        $scope.uploadErrors = [];
        $scope.data = {fields: {
                geminiApp: true
            }, file: undefined};
        $scope.currentUser=currentUser;

        $scope.upload = function(file, fields) {
            $scope.uploadedFile = undefined;
            $scope.uploadErrors = [];
            aerosAdminApi.uploadRogueSoftware(file, fields).then(function(resp) {
            	console.log("uploadRogueSoftware");

                $scope.uploadedFile = resp.data.update.latestFile;
                $uibModalInstance.close(resp.data.update);
            }).
            then(Notification.success.bind(Notification, 'Successfully uploaded, you can check the file')).
            then(reload).
            catch(function(resp) { $scope.uploadErrors = resp.data.errors; });
        };

        $scope.checkFileName = function(fileName) {
            var regex = /([a-zA-Z0-9\.]+)_([\d]+)_([\d]+)([\d]+)?\.([a-zA-Z]+)/;
            if (!fileName.match(regex)) {
                $scope.uploadErrors = [ "Invalid artifact file name, expected <package>_<major>_<minor>.<extension>" ];
            } else {
                //$scope.uploadErrors = [];
                checkPackageExist(fileName);
            }
        };

        function checkPackageExist (fileName) {
            aerosAdminApi.checkPackageNameExist(fileName).then(function(resp) {
                console.log("checkPackageNameExist:- " + resp.data.exist);
                if (resp.data.exist != false) {
                    $scope.uploadErrors = ["Package Already Exists."];
                }
            })
    };}
    EditSoftwareModalController.$inject = [ "$scope", "aerosAdminApi", "reload", "software",  "$uibModalInstance", "Notification" ];
    function EditSoftwareModalController($scope, aerosAdminApi, reload, software, $uibModalInstance, Notification) {

    	//This should call a REST for just updating the meta data for a package.
    	//
    	$scope.pkg = software.pkg;
        $scope.uploadErrors = [];
        $scope.data = {
        	fields: {
        		packageName: software.pkg,
        		softwareName: software.softwareName,
        		softwareDescription: software.softwareDescription,
                metadataValue: software.metadataValue,
                geminiApp:software.geminiApp,
                deleteIcon: undefined
       		},
        file: undefined};

        $scope.upload = function(file, fields) {
            $scope.uploadedFile = undefined;
            $scope.uploadErrors = [];
            aerosAdminApi.editRogueSoftware($scope.pkg, fields, file).then(function(resp) {
                console.log("deleteoptionvalue:"+ fields.deleteIcon);
            	console.log("editRogueSoftware");
                $uibModalInstance.close(resp.data.update);
            }).then(Notification.success.bind(Notification, 'Successfully updated, you can check the file')).
            then(reload).
            catch(function(resp) { $scope.uploadErrors = resp.data.errors; });
        };

        $scope.cancel = function() {
			$uibModalInstance.dismiss();
		};
    }

    EditSoftwareVersionModalController.$inject = [ "$scope", "aerosAdminApi", "reload", "software","uploadUrl","currentUser", "$uibModalInstance", "Notification" ];
    function EditSoftwareVersionModalController($scope, aerosAdminApi, reload, software,uploadUrl,currentUser, $uibModalInstance, Notification) {
        console.log("inside modal controller");
        $scope.uploadUrl = uploadUrl;
        $scope.uploadedFile = undefined;
        $scope.uploadErrors = [];
        $scope.data = {file: undefined};
        $scope.pkg= software.pkg;
        $scope.currentUser=currentUser;

        $scope.upload = function(file, fields) {
            $scope.uploadedFile = undefined;
            $scope.uploadErrors = [];
            aerosAdminApi.uploadRogueSoftware(file, fields).then(function (resp) {
                console.log("uploadRogueSoftware");

                $scope.uploadedFile = resp.data.update.latestFile;
                $uibModalInstance.close(resp.data.update);
            }).
                then(Notification.success.bind(Notification, 'Successfully uploaded, you can check the file')).
                then(reload).
                catch(function (resp) {
                    $scope.uploadErrors = resp.data.errors;
                });
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss();
        };

        $scope.checkFileName = function(fileName) {
            var regex = /([a-zA-Z0-9\.]+)_([\d]+)_([\d]+)([\d]+)?\.([a-zA-Z]+)/;
            if (!fileName.match(regex)) {
                $scope.uploadErrors = [ "Invalid artifact file name, expected <package>_<major>_<minor>.<extension>" ];
            } else {
                if(fileAllowed(fileName)){
                    $scope.uploadErrors =[];
                }else {
                    $scope.uploadErrors = ["Please add latest version of selected package only"];
                }
            }
        };

    function fileAllowed (fileName) {
            var regex = /([a-zA-Z0-9\.]+)_([\d]+)_([\d]+)([\d]+)?\.([a-zA-Z]+)/;
            if (fileName.match(regex)) {
               var match= fileName.split("_");
               var pkgName= match[0];
                console.log("existingPackage:"+software.pkg);
                console.log("packageName:"+pkgName);
                if(pkgName==software.pkg) {
                    console.log("LatestVersion:"+ software.latestVersion);
                  var output =  checkVersion(fileName,software.latestVersion);
                   return output;
                } else{
                    return false;
                }
            }
        }

        function checkVersion(fileName,pkg){
            var newFileVersions = fileName.split("_");
            var oldFileVersions = pkg.split(".");
            if(newFileVersions[1] > oldFileVersions[0]){
                return true;
            }else if(newFileVersions[1]< oldFileVersions[0]){
                return false;
            }else{
                   if(newFileVersions[2]> oldFileVersions[1]){
                       return true;
                  }
                else if(newFileVersions[2] < oldFileVersions[1]){
                    return false;
                }else{
                       if(newFileVersions[3]!=null && oldFileVersions[2]!=null){
                           if(newFileVersions[3] > oldFileVersions[2]){
                               return true;
                           }else if(newFileVersions[3] < oldFileVersions[2]){
                               return false;
                           }
                       }else if(newFileVersions[3]!=null && oldFileVersions[2]==null){
                           return true;
                       }else if(newFileVersions[3]==null && oldFileVersions[2]!=null){
                           return false;
                       }

                   }
            }


        }
    }

    showSoftwareUpdateInfoController.$inject = [ "$scope", "aerosAdminApi", "reload","software","$uibModalInstance", "Notification" ];
    function showSoftwareUpdateInfoController($scope, aerosAdminApi, reload, software,$uibModalInstance, Notification) {
        $scope.package = software;

    }

})();
